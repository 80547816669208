import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useLocation } from "@reach/router"

import globalStyles from "../services/globalStyles.module.scss"
import styles from "./features.module.scss"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import TrackVisibility from "../components/trackVisibility"
import FeatureTMP from "../components/sportDetail/featureTMP"
import Landing from "../components/features/landing"
import GetStarted from "../components/getStarted"

import Button from "../components/button"
import FeaturesPanel from "../components/features/featuresPanel"
import {
  CollectionsIcon,
  CommunicationIcon,
  DiagramIcon,
  InjuryIcon,
  MonitoringIcon,
  PlanningIcon,
  VideoAnalysisIcon,
} from "../components/svgIcons"
import SeeHowItWorks from "../components/index/seeHowItWorks"

function FeaturesPage(props) {
  const { hash } = useLocation()
  const scrolledRef = React.useRef(false)
  const hashRef = React.useRef(hash)
  const { pageContext } = props
  const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en"
  const {
    texts: { _ },
    headerImgs,
    collectionsImgs,
    communicationImgs,
    diagramsImgs,
    injuryImgs,
    monitoringImgs,
    planningImgs,
    videoAnalysisImgs,
  } = props.data

  const imgs = useMemo(
    () =>
      [
        planningImgs,
        monitoringImgs,
        communicationImgs,
        diagramsImgs,
        videoAnalysisImgs,
        collectionsImgs,
        injuryImgs,
      ].map((sectionImgs) =>
        sectionImgs.nodes.map((img) => ({
          video: img.childImageSharp.fluid.src,
        }))
      ),
    [props.data]
  )

  const faturesPanelData = [
    {
      id: "planning",
      title: _.features.sections[0].section_header,
      icon: PlanningIcon,
    },
    {
      id: "monitoring",
      title: _.features.sections[1].section_header,
      icon: MonitoringIcon,
    },
    {
      id: "communication",
      title: _.features.sections[2].section_header,
      icon: CommunicationIcon,
    },
    {
      id: "tactics",
      title: _.features.sections[3].section_header,
      icon: DiagramIcon,
    },
    {
      id: "video_analysis",
      title: _.features.sections[4].section_header,
      icon: VideoAnalysisIcon,
    },
    {
      id: "collections",
      title: _.features.sections[5].section_header,
      icon: CollectionsIcon,
    },
    {
      id: "injury_management",
      title: _.features.sections[6].section_header,
      icon: InjuryIcon,
    },
  ]

  useEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash
        scrolledRef.current = false
      }

      if (!scrolledRef.current) {
        const id = hash.replace("#", "")
        const element = document.getElementById(id)

        if (element) {
          const scrollTimeout = setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" })
            scrolledRef.current = true
          }, 300)

          return () => clearTimeout(scrollTimeout)
        }
      }
    }
  }, [hash])

  return (
    <Layout
      header={
        <Header
          num={1}
          translations={_}
          headerFullYOffset={600}
          fixed
          languageCode={languageCode}
        />
      }
      translations={_}
      languageCode={languageCode}
    >
      <FeaturesPanel data={faturesPanelData} />
      <Landing
        heading={_.features.title}
        descriptionItems={_.features.sections.map((f) => f.section_header)}
        image={headerImgs.edges[0]}
        actionButton={
          <Button
            classStyle={styles.actionButton}
            onClick={() => scrollTo(`#${faturesPanelData[0].id}`)}
          >
            {_.feature.see_all}
          </Button>
        }
      />
      <div>
        {_.features.sections.map((f, i) => (
          <div
            className={["feature-section", styles.featureSection].join(" ")}
            key={f.section_header}
            id={faturesPanelData[i].id}
          >
            <TrackVisibility partialVisibility key={i}>
              {({ isVisible }) => (
                <FeatureTMP
                  key={i}
                  isVisible={isVisible}
                  dividerColor={styles.green}
                  videos={imgs[i]}
                  left={i % 2 === 0}
                  texts={f}
                  slowAnimation
                />
              )}
            </TrackVisibility>
            {(i === 1 || i === 4) && <SeeHowItWorks translations={_} />}
          </div>
        ))}
      </div>
      <GetStarted languageCode={languageCode} translations={_} />
      <Header
        num={2}
        className={globalStyles.hideOnMobile}
        translations={_}
        headerFullYOffset={600}
        boxShadow
        languageCode={languageCode}
      />
      <Footer translations={_} languageCode={languageCode} />
    </Layout>
  )
}

export default FeaturesPage

export const query = graphql`
  query Features($langFile: String) {
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        start_free_trial
        ready_to_start
        enter_your_email
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
        features {
          title
          sections {
            section_header
            section_description
            texts {
              header
              paragraph
            }
          }
        }
        feature {
          see_all
        }
      }
    }
    headerImgs: allFile(
      filter: { relativePath: { glob: "features/landing*" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          extension
          relativePath
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    planningImgs: allFile(
      filter: { relativePath: { glob: "features/planning*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    monitoringImgs: allFile(
      filter: { relativePath: { glob: "features/monitoring*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    communicationImgs: allFile(
      filter: { relativePath: { glob: "features/communication*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    diagramsImgs: allFile(
      filter: { relativePath: { glob: "features/diagrams*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    videoAnalysisImgs: allFile(
      filter: { relativePath: { glob: "features/video_analysis*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    collectionsImgs: allFile(
      filter: { relativePath: { glob: "features/collections*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    injuryImgs: allFile(
      filter: { relativePath: { glob: "features/injury*" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        extension
        relativePath
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
