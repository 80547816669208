import React, { useState, useMemo, useEffect } from "react"
import styles from "./featuresPanel.module.scss"
import scrollTo from "gatsby-plugin-smoothscroll"

const FeaturesPanel = ({ data }) => {
  const [activeSection, setActiveSection] = useState("")

  const sectionElements = useMemo(() => {
    if (typeof document !== "undefined") {
      return document.getElementsByClassName("feature-section")
    } else return []
  }, [])

  const handleScroll = () => {
    let i = 0
    for (const el of sectionElements) {
      const rect = el.getBoundingClientRect()
      if (i === 0 && rect.top > visualViewport.height * 0.4) {
        setActiveSection("")
        history.replaceState(null, null, window.location.toString().split("#")[0])
        return
      }
      if (activeSection !== data[i].id) {
        if (
          rect.top <= visualViewport.height * 0.4 &&
          // -83 according negative margin in section
          rect.top + rect.height - 83 > visualViewport.height * 0.4
        ) {
          setActiveSection(data[i].id)
          const location = window.location.toString().split("#")[0]
          history.replaceState(null, null, location + "#" + data[i].id)
          return
        }
      }
      i++
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () => document.removeEventListener("scroll", handleScroll)
  }, [activeSection])

  return (
    <div className={styles.wrapper}>
      {data.map((d) => (
        <button
          className={activeSection === d.id ? styles.active : null}
          onClick={() => scrollTo(`#${d.id}`)}
        >
          <span className={styles.text}>{d.title}</span>
          <d.icon />
        </button>
      ))}
    </div>
  )
}

export default FeaturesPanel
