import React from "react"

import GatsbyImage from "gatsby-image"

import styles from "./landing.module.scss"

const Landing = ({ heading, descriptionItems, image, actionButton }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textsWrapper}>
        <h1>{heading}</h1>
        <ul>
          {descriptionItems.map((d, i) => (
            <li key={i}>{d}</li>
          ))}
        </ul>
        {actionButton}
      </div>
      <div className={styles.imageWrapper}>
        <GatsbyImage fluid={image.node.childImageSharp.fluid} />
      </div>
    </div>
  )
}

export default Landing
